<template>
    <div class="askbot-app-list">
        <div v-if="publishAppList.length != 0" class="aal-title">
            <span class="aal-title-name">已选择应用</span>
            <div v-if="false" class="aal-title-handle">
                <span>创建</span>
            </div>
        </div>
        <div ref="botListContent" v-loading="publishAppLoading" class="aal-List">
            <div class="aal-List-cell" v-for="(item, itemIndex) in publishAppList" :key="itemIndex"
                @click="showAppDetail(item)">
                <div class="aal-List-cell-title">
                    <img class="alct-avt" height="43px" width="43px" :src="item.logo" alt="" srcset="">
                    <div class="alct-otherinfo-top">
                        <span class="alct-name">{{ item.name }}</span>
                        <el-tag v-if="!item.configStatus" size="mini" type="danger">未配置</el-tag>
                        <el-tag v-else size="mini" type="success">已配置</el-tag>
                    </div>
                </div>
                <div class="aal-List-cell-des">
                    {{ item.desc }}
                </div>
            </div>
        </div>
        <!-- <div v-if="publishAppList.length == 0 && !publishAppLoading" class="no-answer-box-askapp">
            <div class="image-box">
                <img width="251px" height="182px"
                    src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png" alt />
            </div>
            <div class="no-answer-content">
                <span class="no-answer-title">暂无选择应用</span>
                <span class="no-answer-tip">可选择并点击下方应用列表中应用并开通</span>
            </div>
        </div> -->
        <span class="aal-hr"></span>
        <div class="aal-title">
            <div class="aal-title-name">
                <span class="aal-title-name-pre">全部应用</span>
                <div class="aal-title-name-noapp">
                    <span class="atnn-pre">没找到应用?</span>
                    <span class="atnn-aft-1">联系我们 </span>
                    <span class="atnn-aft-2"> /创建应用</span>
                </div>
            </div>
            <div class="aal-title-handle">
                <el-input size="small" @keyup.enter.native="getAppList(true)" placeholder="搜索应用名称"
                    prefix-icon="el-icon-search" v-model="keyword">
                </el-input>
            </div>
        </div>
        <div class="aal-class">
            <div class="aal-class-content">
                <span @click="searchByClass(-1)"
                    :class="['aal-class-cell', activeClass == -1 && 'aal-class-cell-active']">全部分类</span>
                <span v-for="(classCell, classCellIndex) in classOptions" :key="classCellIndex"
                    @click="searchByClass(classCellIndex)"
                    :class="['aal-class-cell', activeClass == classCellIndex && 'aal-class-cell-active']">{{
                        classCell.label }}</span>
            </div>
            <!-- <span class="aal-class">
                更多 <span class="el-icon-arrow-right"></span>
            </span> -->
        </div>
        <div ref="botListContentBottom" v-loading="appListLoading" class="aal-List">
            <div class="aal-List-cell" v-for="(item, itemIndex) in appList" :key="itemIndex" @click="showAppDetail(item)">
                <div class="aal-List-cell-title">
                    <img class="alct-avt" height="43px" width="43px" :src="item.logo" alt="" srcset="">
                    <div class="alct-otherinfo-bottom">
                        <span class="alct-name">{{ item.name }}</span>
                    </div>
                </div>
                <div class="aal-List-cell-des">
                    {{ item.desc }}
                </div>
            </div>
        </div>
        <div v-if="appList.length == 0 && !appListLoading" class="no-answer-box-askapp">
            <div class="image-box">
                <img width="251px" height="182px"
                    src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png" alt />
            </div>
            <div class="no-answer-content">
                <span class="no-answer-title">当前查询条件暂无应用</span>
            </div>
        </div>
    </div>
</template>
<script>
import elementResizeDetectorMaker from "element-resize-detector";
export default {
    data() {
        return {
            name: "askbot-app-list",
            keyword: "",
            activeClass: -1,
            classOptions: [
                { value: 'AI', label: "ai人工智能" },
                { value: 'OA', label: "协同办公系统" },
                { value: 'B2C', label: "电商平台" },
                { value: 'PRIVATEAPP', label: "私有应用" },
                { value: 'ERP', label: "财务/资源管理(ERP)" },
                { value: 'CRM', label: "客户关系管理(CRM)" },
                { value: 'MESSAGING', label: "邮件/短信服务" },
                { value: 'CMS', label: "内容管理系统" },
                { value: 'UEBA', label: "用户行为分析" },
            ],
            appList: [],
            publishAppList: [],
            publishAppLoading: false,
            appListLoading: false,
            activeApp: null,
        }
    },
    components: {
    },
    methods: {
        showAppDetail(data) {
            this.$emit('showDetail', { visible: false, id: data.id });
        },
        searchByClass(index) {
            this.activeClass = index;
            this.getAppList();
        },
        getAppList(isSearch) {
            console.log(this.keyword.trim());
            this.appListLoading = true;
            let param = {
                page: 0,
                size: 200,
                sortByProperty: 'updateTime',
                direction: 'DESC',
                keyWord: this.keyword.trim(),
            }
            if (this.activeClass != -1) {
                param.type = this.classOptions[this.activeClass].value;
            }
            this.FetchGet(this.requestUrl.appStore.getAppList, param).then((res) => {
                // 接口返回格式不同
                // if (res.code === "0") {
                //     console.log(res.data);
                //     this.appList = res.data;
                //     this.$nextTick(() => {
                //         this.watchSize_b();
                //     })
                // } else {
                //     this.$message.error(res.message);
                // }

                console.log(res.content);
                this.appList = res.content;
                if (this.appList.length > 0 || isSearch) {
                    this.keyword = "";
                }
                this.appListLoading = false;
                // this.$nextTick(() => {
                //     this.watchSize_b();
                // })
            });
        },
        getPublishAppList() {
            this.publishAppLoading = true;
            this.FetchGet(this.requestUrl.appStore.getPublishAppList, { publish: true }).then((res) => {
                if (res.code === "0") {
                    console.log(res.data);
                    this.publishAppList = res.data.content;
                    // this.$nextTick(() => {
                    //     this.watchSize();
                    // })
                } else {
                    this.$message.error(res.message);
                }
                this.publishAppLoading = false;
            });
        },

        // 待封装优化代码段 liangpengyu
        watchSize() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            erd.listenTo(_this.$refs.botListContent, (element) => {
                _this.$nextTick(() => {
                    if (_this.listLen !== 0) {
                        _this.addEmptyEl(_this.listLen);
                    }
                })
            });
        },
        // 待封装优化代码段 liangpengyu
        addEmptyEl(listLen) {
            console.log(this.$refs.botListContent);
            if (this.$refs.botListContent != undefined) {
                let outerW = this.$refs.botListContent.offsetWidth; // 容器宽度
                let cellW = this.$refs.upAddBot.offsetWidth; // 每个cell宽度
                let evNum = Math.floor(outerW / cellW); // 每行个数
                let needAddNum = (evNum - ((listLen + 1) % evNum)) === evNum ? 0 : (evNum - ((listLen + 1) % evNum)); // 最后一行需要补空意图数
                this.needAddNum = needAddNum;
                this.needAddNumArr = "11111111111".substring(0, needAddNum).split("");
            }
        },

        // 待封装优化代码段 liangpengyu
        watchSize_b() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            erd.listenTo(_this.$refs.botListContentBottom, (element) => {
                _this.$nextTick(() => {
                    if (_this.listLen !== 0) {
                        _this.addEmptyEl_b(_this.listLen);
                    }
                })
            });
        },
        // 待封装优化代码段 liangpengyu
        addEmptyEl_b(listLen) {
            if (this.$refs.botListContentBottom != undefined) {
                let outerW = this.$refs.botListContentBottom.offsetWidth; // 容器宽度
                let cellW = this.$refs.upAddBot.offsetWidth; // 每个cell宽度
                let evNum = Math.floor(outerW / cellW); // 每行个数
                let needAddNum = (evNum - ((listLen + 1) % evNum)) === evNum ? 0 : (evNum - ((listLen + 1) % evNum)); // 最后一行需要补空意图数
                this.needAddNum = needAddNum;
                this.needAddNumArr = "11111111111".substring(0, needAddNum).split("");
            }
        },
    },
    mounted() {
        this.getAppList();
        this.getPublishAppList();
    },
}
</script>
<style lang="less">
@import './../../assets/less/main/common.less';

.askbot-app-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .no-answer-box-askapp {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin: 50px;
        padding-bottom: 50px;

        .no-answer-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: @system_fontcolor_sub;
            padding: 8px;

            .no-answer-title {
                font-size: 18px;
            }
        }
    }

    .aal-class {
        display: flex;
        justify-content: flex-start;
        padding: 0 16px;

        .aal-class-content {
            flex-wrap: wrap;
            justify-self: start;
            display: flex;

            .aal-class-cell {
                padding: 0 10px;
                margin-right: 8px;
                margin-bottom: 8px;
                height: 29px;
                line-height: 31px;
                background: #FFFFFF;
                border: 1px solid #C7D7FF;
                border-radius: 17px;
                font-size: 12px;
                color: #A3ADC6;
                font-weight: 500;
                cursor: pointer;
            }

            .aal-class-cell:hover {
                border: 1px solid #366AFF;
            }

            .aal-class-cell-active {
                background-color: #366AFF;
                color: #fff;
            }
        }
    }

    .aal-hr {
        display: block;
        height: 1px;
        background-color: #E3E9F4;
        margin: 30px 0 0;
    }

    .aal-title {
        margin: 16px 14px 10px 14px;
        display: flex;
        justify-content: space-between;

        .aal-title-name {
            width: 300px;
            text-align: left;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #366AFF;
            display: flex;
            align-items: center;

            .aal-title-name-pre {
                margin-right: 8px;
            }

            .aal-title-name-noapp {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;

                .atnn-pre {
                    color: #A3ADC6;
                    margin-right: 4px;
                }

                .atnn-aft-1 {
                    color: #366AFF;
                }

                .atnn-aft-2 {
                    color: #366AFF;
                }
            }
        }
    }

    .aal-List {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 8px 14px 0px;
        padding-bottom: 24px;

        .aal-List-cell:hover {
            border-color: #B8CCFF;
        }

        .aal-List-cell {
            flex: none;
            margin-right: 14px;
            margin-top: 12px;
            width: 280px;
            // min-width: 232px;
            // max-width: 304px;
            height: 138px;
            // background: #366AFF;
            box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
            border-radius: 5px;
            border: 2px solid #fff;
            cursor: pointer;

            .aal-List-cell-title {
                display: flex;
                justify-self: start;
                margin: 16px 16px 12px;

                .alct-avt {
                    width: 43px;
                    height: 43px;
                    background: #D1DEFF;
                    border-radius: 5px;
                    overflow: hidden;
                    flex: none;
                }

                .alct-otherinfo-top {
                    display: flex;
                    flex-direction: column;
                    flex: auto;
                    margin-left: 12px;

                    .alct-name {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // 超出多少行
                        -webkit-box-orient: vertical;
                    }

                    .el-tag {
                        width: 60px;
                    }
                }

                .alct-otherinfo-bottom {
                    display: flex;
                    flex: auto;
                    margin-left: 12px;
                    align-items: center;

                    .alct-name {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // 超出多少行
                        -webkit-box-orient: vertical;
                    }
                }
            }

            .aal-List-cell-des {
                margin: 0 16px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #A9B3C6;
                line-height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; // 超出多少行
                -webkit-box-orient: vertical;
                text-align: left;
            }
        }
    }

}
</style>