<template>
    <div class="askbot-app-store">
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <span v-show="!listVisible" @click="listVisible = true"
                    class="ask-top-return iconfont guoran-tongyichicun-18-22-fanhui"></span>
                <i class="iconfont guoran-fangwenyingyong"></i>
                <span class="page-name">应用中心</span>
            </div>
        </page-top-header>
        <div class="aas-content">
            <app-list v-show="listVisible" ref="applist" @showDetail="showDetail"></app-list>
            <app-detail ref="appDetail" v-if="!listVisible" :activeApp="activeApp" @publishSucc="publishSucc"></app-detail>
        </div>
    </div>
</template>
<script>
import pageTopHeader from "../../components/page-top-header.vue";
import AppList from "./AppList.vue";
import AppDetail from "./AppDetail.vue";
export default {
    data() {
        return {
            name: "果然应用中心",
            listVisible: true,
            activeApp: null,
        }
    },
    components: {
        pageTopHeader,
        AppList,
        AppDetail
    },
    methods: {
        publishSucc() {
            this.getAppDetail(this.activeApp.id);
            this.$refs.applist.getAppList();
            this.$refs.applist.getPublishAppList();
        },
        showDetail(val) {
            this.getAppDetail(val.id);
        },
        getAppDetail(id) {
            this.FetchGet(this.requestUrl.appStore.getAppDetail, { id: id }).then((res) => {
                console.log(res.data);
                this.activeApp = res.data;
                this.listVisible = false;
                this.$nextTick(() => {
                    this.$refs.appDetail.getAppAuthConfig();
                    this.$refs.appDetail.getActionList();
                })
            });
        },
    }
}
</script>
<style lang="less">
.askbot-app-store {
    height: 100vh;
    padding-bottom: 20px;

    .ask-top-return {
        display: inline-block;
        border-right: solid 1px lightgray;
        height: 24px;
        line-height: 24px;
        width: 30px;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        color: #366AFF;
        margin-right: 12px;
        margin-left: 8px;
    }

    .aas-content {
        padding: 0;
        display: flex;
        height: 100%;
        box-shadow: 0px 0px 18px 0px rgb(29 55 129 / 7%);
        border-radius: 5px;
        font-family: Microsoft YaHei;
        color: #000;
        margin-bottom: 14px;
        height: calc(100vh - 105px);
        background-color: white;
        margin-left: 16px;
        margin-right: 16px;
        overflow-y: auto;
    }
}
</style>