<template>
    <div class="askbot-app-detail">
        <div class="aal-List-cell-title">
            <img class="alct-avt"
                src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2022/12/09/05/35/8906d62f-280e-4e77-b3cc-6a0db09185b3/c7fcfcf5-50c6-45ee-8e79-9d572e7c17ba1.jfif"
                alt="" srcset="">
            <div class="alct-otherinfo">
                <div class="alct-name">
                    <span class="alct-name-pre">
                        <span class="alct-name-text">{{ activeApp.name }}</span>
                        <el-tag v-if="activeApp.configStatus" size="mini">已配置</el-tag>
                        <el-tag v-else size="mini" type="danger">未配置</el-tag>
                    </span>
                    <span v-if="activeApp.publish" class="alct-name-after">
                        <span class="alct-name-after-succ">
                            <span class="el-icon-check"></span>
                            <span>已开通</span>
                        </span>
                    </span>
                    <!-- <span v-else @click="publishApp" class="alct-name-after-handle">开 通</span> -->
                </div>
                <div class="aal-List-cell-des">
                    {{ activeApp.desc }}
                </div>
            </div>
        </div>
        <div class="apd-detail-content">
            <div class="apd-detail-content-tab-box">
                <div v-for="(item, itemIndex) in tabList" :key="itemIndex" @click="activeTab = itemIndex"
                    class="apd-detail-content-tab">
                    <span :class="['adct-text', activeTab == itemIndex && 'adct-text-active']">{{ item.name }}</span>
                    <!-- <el-tag v-show="itemIndex == 0" type="danger" size="mini">未配置</el-tag> -->
                </div>
            </div>
            <span class="apd-detail-content-hr"></span>
            <div v-show="activeTab == 0 && keyTableData.length != 0" class="apd-detail-content-auth">
                <div v-for="(item, itemIndex) in keyTableData" :key="itemIndex" class="apd-detail-content-auth-cell">
                    <span class="adcac-title">{{ item.name }} <span v-show="item.desc != ''" class="adcac-title-desc">({{
                        item.desc }})</span></span>
                    <el-input v-model="item.authvalue" placeholder="请输入"></el-input>
                </div>
                <div v-if="!activeApp.publish" class="alct-name-after-handle-box">
                    <span @click="publishApp" class="alct-name-after-handle">开 通</span>
                </div>
            </div>
            <div v-show="activeTab == 0 && keyTableData.length == 0" class="apd-detail-content-auth">
                <div class="no-answer-box-askapp">
                    <div class="image-box">
                        <img width="251px" height="182px"
                            src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png" alt />
                    </div>
                    <div class="no-answer-content">
                        <span class="no-answer-title">暂无授权字段可配置</span>
                        <span class="no-answer-tip">请在应用配置平台配置授权字段</span>
                    </div>
                </div>
            </div>
            <div v-show="activeTab == 1 && actionData.length != 0" class="apd-detail-content-action">
                <div v-for="(cell, cellIndex) in actionData" :key="cellIndex"
                    :class="['apd-detail-content-action-cell', cellIndex !== 0 && 'apd-border-top']">
                    <div class="adcac-title">
                        <span class="adcac-title-name">{{ cellIndex + 1 }}、{{ cell.actionMsg.name }}</span>
                        <el-switch v-model="cell.actionValue">
                        </el-switch>
                    </div>
                    <div class="adcac-desc">{{ cell.actionMsg.actionDes }}</div>
                </div>

            </div>
            <div v-show="activeTab == 1 && actionData.length != 0" class="alct-name-after-handle-box">
                <span @click="publishApp" class="alct-name-after-handle">保 存</span>
            </div>
            <div v-show="activeTab == 1 && actionData.length == 0" class="apd-detail-content-auth">
                <div class="no-answer-box-askapp">
                    <div class="image-box">
                        <img width="251px" height="182px"
                            src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png" alt />
                    </div>
                    <div class="no-answer-content">
                        <span class="no-answer-title">暂无配置执行动作</span>
                        <span class="no-answer-tip">请在应用配置平台配置执行动作</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            name: "askbot-app-list",
            tabList: [{
                value: 0,
                name: '授权与认证'
            }, {
                value: 1,
                name: '执行动作'
            },
                // {
                //     value: 2,
                //     name: '参数预览'
                // }, {
                //     value: 3,
                //     name: '返回值预览'
                // }
            ],
            activeTab: 0,
            keyTableData: [],
            actionData: []
        }
    },
    props: ['activeApp'],
    components: {
    },
    methods: {
        publishApp() {
            let devAppAuthValue = {}, actionFiledSwitch = {};
            this.keyTableData.forEach(item => {
                devAppAuthValue[item.id] = item.authvalue;
            })
            this.actionData.forEach(item => {
                let key = String(item.id);
                actionFiledSwitch[key] = item.actionValue;
            })
            let param = {
                appId: this.activeApp.id,
                devAppAuthValue: JSON.parse(JSON.stringify(devAppAuthValue)),
                actionFiledSwitch: actionFiledSwitch
            }

            console.log(param);
            this.$http.post(this.requestUrl.appStore.publishApp, param).then((res) => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data.code == '0') {
                        this.$emit('publishSucc');
                    }
                }
            });
        },
        getAppAuthConfig() {
            console.log(421);
            this.$http.get(this.requestUrl.appStore.getConfig + this.activeApp.id).then(res => {
                if (res.status === 200) {
                    if (res.data === "") {
                        this.keyTableData = [];
                    } else {
                        let arr = res.data.params;
                        arr.forEach(element => {
                            if (this.activeApp.publish) {
                                element.authvalue = this.activeApp.devAppAuthValue[element.id];
                            } else {
                                element.authvalue = '';
                            }
                        });
                        this.keyTableData = arr;
                    }

                }
                console.log(res);
            })
        },
        getActionList() {
            this.$http.get(this.requestUrl.appStore.getActionList + this.activeApp.id).then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    let arr = res.data;
                    arr.forEach(element => {
                        if (this.activeApp.publish) {
                            this.activeApp.actions.forEach(cell => {
                                if (cell.id == element.id) {
                                    element.actionValue = cell.doSomething;
                                }
                            })
                        } else {
                            element.actionValue = true;
                        }

                    });
                    this.actionData = [...arr];
                }
            })
        },
    },
    mounted() {
        // this.getAppAuthConfig();
    },
}
</script>
<style lang="less">
@import './../../assets/less/main/common.less';
.askbot-app-detail {
    margin: 0 auto;
    min-width: 700px;

    .no-answer-box-askapp {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin: 50px;
        padding-bottom: 50px;

        .no-answer-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: @system_fontcolor_sub;
            padding: 8px;

            .no-answer-title {
                font-size: 18px;
            }
        }
    }

    .apd-detail-content {
        .apd-detail-content-tab-box {
            display: flex;
            justify-content: flex-start;
            margin: 0px;
            margin-bottom: -1px;

            .apd-detail-content-tab {
                flex: none;
                width: 140px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .adct-text {
                    color: #909399;
                    margin: 0 6px;
                    padding: 6px 0;
                    cursor: pointer;
                }

                .adct-text-active {
                    border-bottom: 2px #366AFF solid;
                }
            }
        }

        .apd-detail-content-hr {
            display: block;
            height: 1px;
            background-color: #E9F9F8;
        }

        .apd-detail-content-auth {
            width: 700px;
            margin: 30px auto;

            .apd-detail-content-auth-cell {
                margin-bottom: 12px;
                display: flex;
                flex-direction: column;

                .adcac-title {
                    text-align: left;
                    line-height: 30px;
                    height: 30px;

                    .adcac-title-desc {
                        color: #C0C4CC;
                        font-size: 12px;
                    }
                }
            }
        }

        .alct-name-after-handle-box {
            display: flex;
            justify-content: flex-end;

            .alct-name-after-handle {
                display: block;
                width: 72px;
                height: 31px;
                background: #366AFF;
                border-radius: 15px;
                line-height: 31px;
                border-radius: 15px;
                font-size: 14px;
                font-weight: 600;
                color: white;
                cursor: pointer;
                margin-top: 30px;
            }
        }

        .apd-detail-content-action {
            border: solid 1px #E0E6F7;
            margin: 30px 0;
            border-radius: 5px;
            overflow: hidden;

            .apd-detail-content-action-cell {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .adcac-title {
                    height: 46px;
                    line-height: 46px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    background: #F7FAFF;

                    .adcac-title-name {
                        flex: auto;
                        text-align: left;
                        margin-left: 12px;
                    }

                    .el-switch {
                        flex: none;
                        margin-right: 12px;
                    }
                }

                .adcac-desc {
                    padding: 12px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #A9B3C6;
                    line-height: 18px;
                    text-align: left;
                }
            }

            .apd-border-top {
                border-top: solid 1px #E0E6F7;
            }
        }
    }

    .aal-List-cell-title {
        display: flex;
        justify-self: start;
        margin: 30px;

        .alct-avt {
            width: 77px;
            height: 77px;
            background: #D1DEFF;
            border-radius: 50%;
            overflow: hidden;
            flex: none;
        }

        .alct-otherinfo {
            display: flex;
            flex-direction: column;
            flex: auto;
            margin-left: 12px;

            .alct-name {
                display: flex;
                justify-content: space-around;
                line-height: 24px;
                height: 40px;
                align-items: center;

                .alct-name-pre {
                    flex: auto;
                    align-items: center;
                    text-align: left;

                    .alct-name-text {
                        font-size: 16px;
                        text-align: left;
                        margin-right: 10px;
                    }

                    .el-tag {}
                }

                .alct-name-after {
                    flex: none;
                    width: 85px;
                    height: 31px;
                    line-height: 31px;
                    background: #E9F9F8;
                    border-radius: 15px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #00C2BB;

                    .el-icon-check {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                .alct-name-after-handle {
                    flex: none;
                    width: 72px;
                    height: 31px;
                    background: #366AFF;
                    border-radius: 15px;
                    line-height: 31px;
                    border-radius: 15px;
                    font-size: 14px;
                    font-weight: 600;
                    color: white;
                    cursor: pointer;
                }

            }

            .aal-List-cell-des {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #A9B3C6;
                line-height: 16px;
                margin-top: 2px;
                text-align: left;
            }
        }
    }
}
</style>